import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import Banner from "./banner"

const BannersBlock = ({ banners }) => (
  <Wrapper>
    <Container>
      <CustomRow>
        {banners.map(banner => (
          <Col md={banners.length > 1 ? 6 : 12} key={banner.id}>
            <Banner
              label={banner.label}
              title={banner.title}
              image={banner.image}
              link={banner.link}
              url={banner.url}
              alternative={banner.alternative}
              wide={banners.length === 1}
            />
          </Col>
        ))}
      </CustomRow>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div``
const CustomRow = styled(Row)`
  @media screen and (max-width: 768px) {
    & > * {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default BannersBlock
