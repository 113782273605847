import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { DisplaySmall } from "./typography"
import SectionTitle from "./sectionTitle"
import NewsWrapper from "./newsWrapper"

const LatestNewsBlock = ({ title, news }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <SectionTitle>
            <DisplaySmall>{title}</DisplaySmall>
          </SectionTitle>
        </Col>
      </Row>
      <Row>
        {news.map(news => (
          <Col md={6} key={news.originalId}>
            <NewsWrapper news={news} />
          </Col>
        ))}
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div``

export default LatestNewsBlock
