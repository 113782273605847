import React from "react"
import styled from "styled-components"
import { Container, Row, Col, ScreenClassRender } from "react-grid-system"
import { DisplaySmall } from "./typography"
import BookThumb from "./bookThumb"
import SectionTitle from "./sectionTitle"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { ArrowLeft, ArrowRight } from "react-feather"
import DesignTokens from "./designTokens"
import CustomContainer from "./customContainer"
SwiperCore.use([Navigation])

const LatestBooksBlock = ({ title, books }) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <SectionTitle>
              <DisplaySmall>{title}</DisplaySmall>
              <PrevNext>
                <button className="prev">
                  <ArrowLeft size={32} />
                </button>
                <button className="next">
                  <ArrowRight size={32} />
                </button>
              </PrevNext>
            </SectionTitle>
          </Col>
        </Row>
      </Container>
      <CustomContainer>
        <Row>
          <Col>
            <ScreenClassRender
              render={screenClass => (
                <Swiper
                  spaceBetween={32}
                  slidesPerView={
                    screenClass === "xl" || screenClass === "xxl" ? 4 : 2
                  }
                  navigation={{
                    nextEl: ".next",
                    prevEl: ".prev",
                  }}
                  watchOverflow
                >
                  {books.map(book => (
                    <SwiperSlide key={book.originalId}>
                      <BookWrapper>
                        <BookThumb book={book} />
                      </BookWrapper>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            />
          </Col>
        </Row>
      </CustomContainer>
    </Wrapper>
  )
}
const Wrapper = styled.div``
const PrevNext = styled.div`
  display: flex;
  button {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    display: flex;
    color: ${DesignTokens.colors.primary[500]};
    background: white;
    border: none;
    cursor: pointer;
    &.swiper-button-disabled {
      cursor: not-allowed;
      color: ${DesignTokens.colors.primary[200]};
    }
  }
`

const BookWrapper = styled.div`
  & > div {
    margin-bottom: 0;
  }
`

export default LatestBooksBlock
