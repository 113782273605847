import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import Banner from "./banner"
import SwiperCore, { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import CustomContainer from "./customContainer"
SwiperCore.use([Pagination, Autoplay])

const HeroBannersBlock = ({ banners }) => (
  <Wrapper>
    <CustomContainer>
      <Row>
        <Col md={12}>
          <Swiper spaceBetween={32} autoHeight={false} slidesPerView={1} speed={1000} loop autoplay pagination>
            {banners.map(banner => (
              <SwiperSlide key={banner.id}>
                <Banner
                  label={banner.label}
                  title={banner.title}
                  image={banner.image}
                  link={banner.link}
                  url={banner.url}
                  hero
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>
    </CustomContainer>
  </Wrapper>
)
const Wrapper = styled.div``

export default HeroBannersBlock
