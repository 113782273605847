import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Caption } from "./typography"
import { ArrowRight } from "react-feather"

const Banner = ({
  label,
  title,
  image,
  link,
  url,
  hero,
  alternative,
  wide,
}) => (
  <Wrapper>
    <Link to={url}>
      <Box hero={hero} alternative={alternative}>
        <CustomBackground hero={hero} alternative={alternative}>
          <Img fluid={image.fluid} />
          {hero && <BackgroundImage />}
        </CustomBackground>
        <Content hero={hero} wide={wide} alternative={alternative}>
          {label && <Label>{label}</Label>}
          <Title alternative={alternative} hero={hero}>
            {title}
          </Title>
          <FakeButton alternative={alternative}>
            {link && <p>{link}</p>}
            <ArrowRight />
          </FakeButton>
        </Content>
      </Box>
    </Link>
  </Wrapper>
)

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #00000044;
  /* z-index: 100; */
`

const Title = styled.h3`
  color: white;
  font-weight: bold;
  font-family: "Poppins";
  font-size: 2rem;
  @media screen and (max-width: 991px) {
    hyphens: auto;
  }
  ${props =>
    props.alternative
      ? `
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 3px;
      `
      : ``}
  ${props =>
    props.hero
      ? `
      font-size: 2rem;
      line-height: 1;
      text-transform: uppercase;
      `
      : ``}
`

const FakeButton = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: "Source Code Pro";
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  border: 2px solid #fff;
  @media screen and (max-width: 768px) {
    width: ${props => (props.alternative ? "auto" : "100%")};
    justify-content: space-between;
  }
  & > * {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

const Content = styled.div`
  box-sizing: border-box;
  backdrop-filter: ${props => (props.hero ? "blur(50px)" : "none")};
  background-color: ${props => (props.hero ? "#00000010" : "transparent")};
  padding: ${props => (props.hero ? "16px 24px" : "0")};
  width: ${props => (props.wide ? "30%" : "60%")};
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    ${props => (props.alternative ? "width:70%;" : "width: 100%;")};
  }
  @media screen and (max-width: 768px) {
    width: ${props => (props.alternative ? "80%" : "100%")};
  }
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Label = styled(Caption)`
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  a {
    text-decoration: none;
    display: block;
    &:hover {
      opacity: 0.8;
    }
  }
`

const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  .gatsby-image-wrapper {
    height: 100%;
    img {
      object-position: ${props =>
        props.hero ? "center center !important;" : "top right !important;"};
      object-position: ${props =>
        props.alternative
          ? "right top !important;"
          : "center center !important;"};
    }
  }
`

const Box = styled.div`
  padding: ${props => (props.hero ? "6rem 2rem 5rem 4rem" : "2rem 2rem 4rem")};
  padding: ${props => (props.alternative ? "2rem 2rem 10rem 2rem" : null)};
  @media screen and (max-width: 767px) {
    padding: ${props =>
      props.hero ? "6rem 2rem 8rem 2rem" : "2rem 2rem 4rem"};
    padding: ${props => (props.alternative ? "2rem 2rem 2rem 2rem" : null)};
    ${props => (props.alternative ? "max-height: 17rem" : null)};
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: ${props =>
      props.hero ? "6rem 2rem 5rem 4rem" : "2rem 2rem 4rem"};
    padding: ${props => (props.alternative ? "2rem 2rem 10rem 2rem" : null)};
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    padding: ${props =>
      props.hero ? "6rem 2rem 5rem 4rem" : "2rem 2rem 4rem"};
    padding: ${props => (props.alternative ? "2rem 2rem 10rem 2rem" : null)};
  }
  ${props => (props.hero ? "min-height: 20rem;" : null)};
  display: flex;
  align-items: center;
`

export default Banner
