import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import HeroBannersBlock from "../components/heroBannersBlock"
import BannersBlock from "../components/bannersBlock"
import LatestBooksBlock from "../components/latestBooksBlock"
import InnerSearchForm from "../components/innerSearchForm"
import LatestNewsBlock from "../components/latestNewsBlock"
import CollectionsBlock from "../components/collectionsBlock"
import { Box } from "theme-ui"

const IndexPage = ({ data: { page, news, collections } }) => {
  // console.log(news)
  return (
    <Layout data-datocms-noindex>
      <SEO title="Home" />
      <Box sx={{ mt: [3, 4, 4], mb: [3, 4, 4] }}>
        <InnerSearchForm />
      </Box>
      <Content>
        {page.content.map(section => (
          <section key={section.id}>
            {section.model.apiKey === "hero_banner_block" && (
              <HeroBannersBlock banners={section.banners} />
            )}
            {section.model.apiKey === "banner_block" && (
              <BannersBlock banners={section.banners} />
            )}
            {section.model.apiKey === "latest_books_block" && (
              <LatestBooksBlock title={section.title} books={section.books} />
            )}
            {section.model.apiKey === "latest_news_block" && (
              <LatestNewsBlock title={section.title} news={news.nodes} />
            )}
            {section.model.apiKey === "collections_block" && (
              <CollectionsBlock
                title={section.title}
                collections={collections.nodes}
              />
            )}
          </section>
        ))}
      </Content>
    </Layout>
  )
}

const Content = styled.div`
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    page: datoCmsHomePage(originalId: { eq: "13126685" }) {
      content {
        ... on DatoCmsHeroBannerBlock {
          id
          model {
            apiKey
          }
          banners {
            label
            title
            link
            url
            image {
              fluid(maxWidth: 920) {
                ...GatsbyDatoCmsFluid
              }
            }
            alternative
            id
          }
        }
        ... on DatoCmsBannerBlock {
          id
          model {
            apiKey
          }
          banners {
            label
            title
            link
            url
            image {
              fluid(maxWidth: 920) {
                ...GatsbyDatoCmsFluid
              }
            }
            alternative
            id
          }
        }
        ... on DatoCmsLatestBooksBlock {
          id
          title
          model {
            apiKey
          }
          books {
            title
            cover {
              fluid(maxWidth: 300) {
                ...GatsbyDatoCmsFluid
              }
            }
            digitalDownload
            newEdition {
              title
              slug
              originalId
            }
            slug
            price
            authors {
              name
              originalId
            }
            originalId
          }
        }
        ... on DatoCmsLatestNewsBlock {
          id
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsCollectionsBlock {
          id
          title
          model {
            apiKey
          }
        }
      }
    }
    news: allDatoCmsNews(sort: { fields: date, order: DESC }, limit: 4) {
      nodes {
        title
        slug
        date(locale: "it", formatString: "D MMMM YYYY")
        summary
        originalId
        image {
          fluid(imgixParams: { fit: "crop", w: "600", h: "600" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        mobileImage {
          fluid(imgixParams: { fit: "crop", w: "600", h: "600" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    collections: allDatoCmsCollection(sort: { fields: name, order: DESC }) {
      nodes {
        name
        slug
        text
        image {
          url
        }
        originalId
      }
    }
  }
`
