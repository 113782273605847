import React from "react"
import styled from "styled-components"
import { Container, Row, Col, ScreenClassRender } from "react-grid-system"
import { CaptionSmall, DisplaySmall } from "./typography"
import SectionTitle from "./sectionTitle"
import CustomContainer from "./customContainer"
import { ArrowLeft, ArrowRight } from "react-feather"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import DesignTokens from "./designTokens"
import Gut from "./gut"
import { Link } from "gatsby"
SwiperCore.use([Navigation])

const CollectionsBlock = ({ title, collections }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <SectionTitle>
            <DisplaySmall>{title}</DisplaySmall>
            <PrevNext>
              <button className="prev-collection">
                <ArrowLeft size={32} />
              </button>
              <button className="next-collection">
                <ArrowRight size={32} />
              </button>
            </PrevNext>
          </SectionTitle>
        </Col>
      </Row>
    </Container>
    <CustomContainer>
      <Row>
        <Col>
          <ScreenClassRender
            render={screenClass => (
              <Swiper
                spaceBetween={32}
                slidesPerView={
                  screenClass === "xl" || screenClass === "xxl" ? 4 : screenClass !== "xs" ? 2 : 1
                }
                navigation={{
                  nextEl: ".next-collection",
                  prevEl: ".prev-collection",
                }}
                watchOverflow
              >
                {collections.map(collection => (
                  <SwiperSlide key={collection.originalId}>
                    <CollectionWrapper>
                      <Link to={`/collana/${collection.slug}`}>
                        <Gut gutter={2}>
                          <Gut gutter={1}>
                            {collection.image && (
                              <img src={collection.image.url} />
                            )}
                            <Label>{collection.name}</Label>
                            <CaptionSmall
                              dangerouslySetInnerHTML={{
                                __html:
                                  collection.text.substring(0, 80) + "...",
                              }}
                            />
                          </Gut>
                          <ArrowRight size={32} />
                        </Gut>
                      </Link>
                    </CollectionWrapper>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          />
        </Col>
      </Row>
    </CustomContainer>
  </Wrapper>
)
const Wrapper = styled.div`
  .swiper-slide {
    height: auto;
  }
`

const CollectionWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  a {
    border: 1px solid ${DesignTokens.colors.primary[100]};
    padding: 1rem;
    display: block;
    height: 100%;
    text-decoration: none;
    &:hover {
      border: 1px solid ${DesignTokens.colors.primary[500]};
    }
  }
`
const Label = styled.div`
  text-transform: uppercase;
  line-height: 1.3;
  hyphens: auto;
`

const PrevNext = styled.div`
  display: flex;
  button {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    display: flex;
    color: ${DesignTokens.colors.primary[500]};
    background: white;
    border: none;
    cursor: pointer;
    &.swiper-button-disabled {
      cursor: not-allowed;
      color: ${DesignTokens.colors.primary[200]};
    }
  }
`

export default CollectionsBlock
