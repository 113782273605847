import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import { Link } from "gatsby"
import Gut from "./gut"
import { Caption, CaptionSmall, Heading } from "./typography"
import Img from "gatsby-image"

const NewsWrapper = ({ news }) => (
  <Wrapper>
    <Link to={`/news/${news.slug}`}>
      <CustomRow gutterWidth={16}>
        <Col md={12} lg={6}>
          <Img
            fluid={news.mobileImage ? news.mobileImage.fluid : news.image.fluid}
          />
        </Col>
        <Col md={12} lg={6}>
          <Gut gutter={1}>
            <Caption>{news.date}</Caption>
            <Heading>{news.title}</Heading>
            <CaptionSmall as="p">{news.summary}</CaptionSmall>
          </Gut>
        </Col>
      </CustomRow>
    </Link>
  </Wrapper>
)

const Wrapper = styled.div`
  margin-bottom: 2rem;
  a {
    text-decoration: none;
    display: block;
    &:hover {
      opacity: 0.8;
    }
  }
  img {
    border: 1px solid #eee;
  }
`

const CustomRow = styled(Row)`
  margin-bottom: 5rem;
  @media screen and (max-width: 991px) {
    & > * {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default NewsWrapper
