import styled from "styled-components"

const CustomContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 960px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    max-width: 750px;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    max-width: 540px;
  }
`

export default CustomContainer
