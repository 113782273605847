import styled from "styled-components"

const SectionTitle = styled.div`
  padding-top: 3rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 1rem;
  }
`

export default SectionTitle
